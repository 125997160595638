.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #434344;
}

.App-link {
  color: #61dafb;
}

.skeleton {
  color: #434344;
  border-radius: 4px;
  height: 50px;
  position: relative;
  overflow: hidden;
  background: #434344;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.skeleton::before {
  content: "";
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(
          to right,
          transparent 0%,
          #434344 50%,
          transparent 100%
  );
  -webkit-text-fill-color: white;
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}
